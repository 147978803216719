<template>
  <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir">
    <div
      class="layout-wrapper flex min-h-screen flex-col justify-between"
      :class="`app theme-${$appConfig ? $theme : 1}`"
    >
      <Navbar version="checkout" />
      <slot />
      <Footer version="checkout" />
    </div>
  </Html>
</template>

<script setup lang="ts">
import Navbar from "@/components/features/Header/Navbar.vue";
import Footer from "@/components/features/Footer/Footer.vue";

const htmlAttrs = computed(() => head.value.htmlAttrs!);

const head = useLocaleHead({
  addDirAttribute: true, // Adds dir
  addSeoAttributes: true, // Adds lang
});
</script>
